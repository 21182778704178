import httpClient from "@/api/httpClient";
const ARTIST = "/artist";

const getArtistFromDbByName = (name) =>
  httpClient.get(`${ARTIST}/db-artists/${name}`);
const getArtistFromChartmetricByName = (name) =>
  httpClient.get(`${ARTIST}/cm-artists/${name}`);

const saveArtistDetailByChartmetricID = (chartmetricID, force = false) =>
  httpClient.post(`${ARTIST}/save-artist/${chartmetricID}/${force}`);

const getArtistDetailByChartmetricID = (chartmetricID) =>
  httpClient.get(`${ARTIST}/artist-detail/${chartmetricID}`);

const getArtistTracksByChartmetricID = (chartmetricID) =>
  httpClient.get(`${ARTIST}/artist-tracks/${chartmetricID}`);

const getArtistListenersByChartmetricID = (chartmetricID) =>
  httpClient.get(`${ARTIST}/artist-listeners/${chartmetricID}`);
const getArtistPlaylistsByChartmetricID = (chartmetricID) =>
  httpClient.get(`${ARTIST}/artist-playlists/${chartmetricID}`);

export {
  getArtistFromDbByName,
  getArtistFromChartmetricByName,
  saveArtistDetailByChartmetricID,
  getArtistDetailByChartmetricID,
  getArtistTracksByChartmetricID,
  getArtistListenersByChartmetricID,
  getArtistPlaylistsByChartmetricID,
};
