import httpClient from "@/api/httpClient";

const TRACK_BASE_URL = "/track-stats";

const getTrackStreamsByChartmetricTrackID = (chartmetricTrackID) =>
  httpClient.get(`${TRACK_BASE_URL}/track-streams/${chartmetricTrackID}`);
const getTrackStreamsDailyDiffByChartmetricTrackID = (chartmetricTrackID) =>
  httpClient.get(`${TRACK_BASE_URL}/daily-track-streams/${chartmetricTrackID}`);

const getPlaylistAudioDnaByCuratorPlaylistID = (curatorPlaylistID) =>
  httpClient.get(`${TRACK_BASE_URL}/playlist-audio-dna/${curatorPlaylistID}`);

const getTrackDetailByChartmetricTrackID = (chartmetricTrackID) =>
  httpClient.get(`${TRACK_BASE_URL}/track/${chartmetricTrackID}`);

const getTracksFromDbByName = (name) =>
  httpClient.get(`${TRACK_BASE_URL}/db-tracks/${name}`);

const getTracksFromChartmetricByName = (name) =>
  httpClient.get(`${TRACK_BASE_URL}/cm-tracks/${name}`);

const getPlaylistRecommendations = (chartmetricTrackID, offset, limit) =>
  httpClient.get(
    `${TRACK_BASE_URL}/recommended-playlist/${chartmetricTrackID}?offset=${offset}&limit=${limit}`
  );

const getPlaylistSimilar = (chartmetricTrackID) =>
  httpClient.get(`${TRACK_BASE_URL}/similar-playlist/${chartmetricTrackID}`);

const saveTrackDetailByChartmetricID = (chartmetricTrackID, force = false) =>
  httpClient.post(
    `${TRACK_BASE_URL}/save-track/${chartmetricTrackID}/${force}`
  );

const saveRecommendedPlaylists = (payload) =>
  httpClient.post(`${TRACK_BASE_URL}/save-track-playlist`, payload);

const deleteRecommendedPlaylist = (chartmetricTrackID, playlistIDs) =>
  httpClient.delete(
    `${TRACK_BASE_URL}/delete-track-playlist-recommendation?chartmetricTrackID=${chartmetricTrackID}&playlistIDs=${playlistIDs}`
  );

export {
  getTrackStreamsByChartmetricTrackID,
  getPlaylistAudioDnaByCuratorPlaylistID,
  getTrackDetailByChartmetricTrackID,
  getTracksFromDbByName,
  getTracksFromChartmetricByName,
  saveTrackDetailByChartmetricID,
  getPlaylistRecommendations,
  getTrackStreamsDailyDiffByChartmetricTrackID,
  saveRecommendedPlaylists,
  deleteRecommendedPlaylist,
  getPlaylistSimilar,
};
