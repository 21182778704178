import axios from "axios";

const httpClient = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  // timeout: 10000,
  headers: {
    "Content-Type": "application/json",
  },
});

httpClient.interceptors.request.use(async (config) => {
  if (config.headers != null && config.headers["X-Requested-With"] == null) {
    config.headers = {
      "X-Requested-With": "XMLHttpRequest",
      Authorization: "Bearer " + "test",
      ...config.headers,
    };
  }
  return config;
});

export default httpClient;
