import httpClient from "@/api/httpClient";
const CURATOR = "/curator";
const CURATOR_FANMETRIC = "/curator-fanmetric";
const HUBSPOT = "/hubspot";

const getAllCurators = (offset, limit, sortBy, sortOrder) =>
  httpClient.get(
    `${CURATOR}/get-all-curators?offset=${offset}&limit=${limit}&sortBy=${sortBy}&sortOrder=${sortOrder}`
  );
const getCuratorsFromDbByName = (name) =>
  httpClient.get(`${CURATOR}/db-curators/${name}`);
const getCuratorsFromChartmetricByName = (name) =>
  httpClient.get(`${CURATOR}/cm-curators/${name}`);

const getCuratorProfileByChartmetricCuratorID = (chartmetricCuratorID) =>
  httpClient.get(`${CURATOR}/profile/${chartmetricCuratorID}`);

const getCuratorsFanmetricByChartmetricCuratorIDSourceAndField = (
  chartmetricCuratorID,
  source,
  field
) =>
  httpClient.get(
    `${CURATOR_FANMETRIC}/curator-series/${chartmetricCuratorID}/${source}/${field}`
  );

const saveCuratorDetailByChartmetricCuratorID = (
  chartmetricCuratorID,
  force = false
) =>
  httpClient.post(`${CURATOR}/save-curator/${chartmetricCuratorID}/${force}`);

const saveCuratorsToHubspot = (payload) =>
  httpClient.post(`${HUBSPOT}/crm-curator`, payload);

const updateCuratorContactsByChartmetricCuratorID = (
  chartmetricCuratorID,
  payload
) =>
  httpClient.put(
    `${CURATOR}/update-curator-contacts/${chartmetricCuratorID}`,
    payload
  );

export {
  getAllCurators,
  getCuratorsFromDbByName,
  getCuratorsFromChartmetricByName,
  getCuratorProfileByChartmetricCuratorID,
  getCuratorsFanmetricByChartmetricCuratorIDSourceAndField,
  saveCuratorDetailByChartmetricCuratorID,
  saveCuratorsToHubspot,
  updateCuratorContactsByChartmetricCuratorID,
};
