import httpClient from "@/api/httpClient";

const PLAYLIST_BASE_URL = "/curator-playlist";
const getTop10kPlaylists = () => httpClient.get(PLAYLIST_BASE_URL);

const getPlaylistDetailByCuratorPlaylistID = (curatorPlaylistID) =>
  httpClient.get(`${PLAYLIST_BASE_URL}/${curatorPlaylistID}`);

const getPlaylistFollowerByCuratorPlaylistID = (curatorPlaylistID) =>
  httpClient.get(`${PLAYLIST_BASE_URL}/followers/${curatorPlaylistID}`);

const getPlaylistTracksByCuratorPlaylistID = (curatorPlaylistID) =>
  httpClient.get(`${PLAYLIST_BASE_URL}/tracks/${curatorPlaylistID}`);

const getTrackDetailInAllPlaylistsByChartmetricTrackID = (chartmetricTrackID) =>
  httpClient.get(`${PLAYLIST_BASE_URL}/track-detail/${chartmetricTrackID}`);

const getCuratorPlaylistByChartmetricCuratorID = (chartmetricCuratorID) =>
  httpClient.get(`${PLAYLIST_BASE_URL}/playlists/${chartmetricCuratorID}`);

const getPlaylistsFromDbByName = (name) =>
  httpClient.get(`${PLAYLIST_BASE_URL}/db-playlists/${name}`);

const getPlaylistsFromChartmetricByName = (name) =>
  httpClient.get(`${PLAYLIST_BASE_URL}/cm-playlists/${name}`);

const getSimilarPlaylists = (curatorPlaylistID, limit) =>
  httpClient.get(
    `${PLAYLIST_BASE_URL}/similar-playlists?curatorPlaylistID=${curatorPlaylistID}&limit=${limit}`
  );
const savePlaylistDetailByCuratorPlaylistID = (
  curatorPlaylistID,
  force = false
) =>
  httpClient.post(
    `${PLAYLIST_BASE_URL}/playlist-detail/${curatorPlaylistID}/${force}`
  );

export {
  getTop10kPlaylists,
  getPlaylistDetailByCuratorPlaylistID,
  getPlaylistTracksByCuratorPlaylistID,
  getTrackDetailInAllPlaylistsByChartmetricTrackID,
  getPlaylistFollowerByCuratorPlaylistID,
  getCuratorPlaylistByChartmetricCuratorID,
  getPlaylistsFromDbByName,
  getPlaylistsFromChartmetricByName,
  getSimilarPlaylists,
  savePlaylistDetailByCuratorPlaylistID,
};
