import $ from "jquery";
import router from "@/router";
import moment from "moment";
import { saveCuratorDetailByChartmetricCuratorID } from "@/api/curators.api";
import { saveArtistDetailByChartmetricID } from "@/api/artists.api";
import { saveTrackDetailByChartmetricID } from "@/api/tracks.api";
import { savePlaylistDetailByCuratorPlaylistID } from "@/api/playlists.api";
import { TypeEnums } from "@/common/enums/typeEnums";
const tableRowClassName = ({ rowIndex }) => {
  return rowIndex % 2 === 0
    ? "table-data-text"
    : "table-data-text striped-table-row";
};

const tableCellClassName = ({ columnIndex }) => {
  if (columnIndex === 0 || columnIndex === 1) {
    return "table-column-ellipses";
  } else {
    return "";
  }
};

const scrollToElementWithMargin = (elementId, marginTop) => {
  const position = document.getElementById(elementId);
  window.scrollTo({
    top: position.offsetTop - marginTop,
    behavior: "smooth",
  });
};

const formatNumberIntoHumanizeForm = (value, fraction = 1) => {
  if (value) {
    if (typeof value === "number") {
      value = Number(value.toFixed(fraction));
    }
    return value.toLocaleString("de-DE");
  } else {
    return 0;
  }
};

const capitalizeFirstLetter = (string) => {
  if (string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  } else {
    return "";
  }
};

const capitalizeEveryWordFirstLetter = (value) => {
  if (value) {
    return value.replace(/(^\w{1})|(\s+\w{1})/g, (letter) =>
      letter.toUpperCase()
    );
  } else {
    return "";
  }
};

const paginate = (array, page_size, page_number) => {
  // human-readable page numbers usually start with 1, so we reduce 1 in the first argument
  return array.slice((page_number - 1) * page_size, page_number * page_size);
};

const sortArray = (sortBy, sortDir, array) => {
  if (sortDir === "ascending") {
    array.sort((a, b) => {
      if (a[sortBy] < b[sortBy]) {
        return -1;
      }
      if (a[sortBy] > b[sortBy]) {
        return 1;
      }
      return 0;
    });
  } else {
    array.sort((a, b) => {
      if (a[sortBy] < b[sortBy]) {
        return 1;
      }
      if (a[sortBy] > b[sortBy]) {
        return -1;
      }
      return 0;
    });
  }
  return array;
};

const checkIsValueAvailableOrNot = (
  type,
  obj,
  objKey,
  unit = "",
  fraction = 1
) => {
  if (type === "object" && obj) {
    return obj[objKey] || obj[objKey] === 0
      ? formatNumberIntoHumanizeForm(obj[objKey], fraction) + unit
      : "N/A";
  } else if (type === "key") {
    return objKey || objKey === 0
      ? formatNumberIntoHumanizeForm(objKey, fraction) + unit
      : "N/A";
  } else if (type === "date") {
    return objKey && obj[objKey]
      ? moment(obj[objKey]).format("MMM DD, YYYY")
      : "N/A";
  }
};

const routeToPage = async (route, type, id) => {
  if (type && id) {
    if (type === TypeEnums.CURATOR) {
      saveCuratorDetailByChartmetricCuratorID(id);
    } else if (type === TypeEnums.ARTIST) {
      saveArtistDetailByChartmetricID(id);
    } else if (type === TypeEnums.TRACK) {
      saveTrackDetailByChartmetricID(id);
    } else if (type === TypeEnums.PLAYLIST) {
      savePlaylistDetailByCuratorPlaylistID(id);
    }
  }
  await router.push(`/${route}`);
};

const getTagName = (array, keyName) => {
  if (array.length) {
    const names = array.map(function (item) {
      return item[keyName];
    });
    const string = names.toString();
    return string.replace(/,/g, ", ");
  } else {
    return "";
  }
};

const getPlatformColor = (platform) => {
  const colors = {
    spotify: "#48B955",
    instagram: "#C14B84",
    soundcloud: "#FB663B",
    twitter: "#01ACEE",
    tiktok: "#000000",
    youtube: "#FA423B",
    deezer: "#e6e600",
    line: "#3AB54A",
    melon: "#41B64A",
    facebook: "#4767AA",
    shazam: "#2979FF",
  };
  return colors[platform];
};

$(document).on("mouseenter", ".truncate", function () {
  const tooltip = $(this).attr("data-tooltip");
  if (tooltip) {
    const tagName = $(this).attr("data-tag");
    const text = $(this).attr("data-text");
    if (this.offsetWidth < this.scrollWidth) {
      $(tagName).attr({
        title: text,
      });
    } else {
      $(tagName).attr({ title: "" });
    }
  }
});
export {
  tableRowClassName,
  tableCellClassName,
  scrollToElementWithMargin,
  formatNumberIntoHumanizeForm,
  capitalizeFirstLetter,
  capitalizeEveryWordFirstLetter,
  paginate,
  sortArray,
  checkIsValueAvailableOrNot,
  routeToPage,
  getTagName,
  getPlatformColor,
};
